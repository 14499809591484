import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sent: false,
  open: false,
}

export const quizPopUpSlice = createSlice({
  name: 'quizPopUp',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true
    },
    close: (state) => {
      state.open = false
    },
    setSent: (state) => {
      state.sent = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { open, close, setSent } = quizPopUpSlice.actions

export default quizPopUpSlice.reducer
