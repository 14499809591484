import { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import s from './AuthPopUp.module.css'
import Photo from '../../res/img/authPopUp.png'
import Close from '../../res/img/close.svg'
import { close as closeAuth, setSent as setSentAuth } from '../../store/authPopUp/authPopUp'
import { open as openQuiz } from '../../store/quizPopUp/quizPopUp'
import ReactGA from 'react-ga'

const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}

export const AuthPopUp = ({ setData }) => {
  const dispatch = useDispatch()
  const [closing, setClosing] = useState(false)
  const [fields, setFields] = useState({
    name: {
      value: '',
      placeholder: 'Enter your name',
      type: 'text',
      valid: true,
    },
    email: {
      value: '',
      placeholder: 'Enter your email',
      type: 'text',
      valid: true,
    },
  })

  const changeHandler = (e) => {
    let copy = { ...fields }
    copy[e.target.name].value = e.target.value
    copy[e.target.name].valid = true
    setFields(copy)
  }

  const validateEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const isValid = () => {
    if (!validateEmail(fields.email.value)) {
      return false
    }
    if (!fields.name.value) {
      return false
    }

    return true
  }

  const Fields = Object.keys(fields).map((key) => {
    return (
      <div className={s.inputBlock} key={key}>
        <input
          className={!fields[key].valid && s.inputInvalid }
          name={key}
          value={fields[key].value}
          placeholder={fields[key].placeholder}
          onChange={changeHandler}
          autoComplete='off'
          spellCheck='false'
        />
        {fields[key].valid ? null : <div className={s.error}>!</div>}
      </div>
    )
  })

  const handleClick = (e) => {
    return e.target.id === 'blur' && setClosing(true)
  }

  const sendData = async (data) => {
    try {
      const res = await axios.post('https://proxy-be-v1-rmot67v4eq-lm.a.run.app/api/v1/forms/register', {
        name: data.name,
        email: data.email,
      })
      console.log(res)
    } catch (err) {
      console.log(err.message)
    }
  }

  const submit = () => {
    if (isValid()) {
      eventTrack('Pop Up', 'Sign Up', 'Auth')
      const body = {
        email: fields.email.value,
        name: fields.name.value,
      }
      console.log(body)
      sendData(body)
      setData(body)
      setClosing(true)
      localStorage.setItem('auth', 'true')
      dispatch(setSentAuth())
      dispatch(openQuiz())
      // next()
    } else {
      let copy = { ...fields }
      if (!validateEmail(copy.email.value)) {
        copy.email.valid = false
      }
      if (!fields.name.value) {
        copy.name.valid = false
      }

      setFields(copy)
    }
  }

  return (
    <div
      className={closing ? `${s.blur} ${s.closing}` : s.blur}
      onAnimationEnd={() => {
        closing && dispatch(closeAuth())
      }}
      onClick={handleClick}
      id='blur'
    >
      <div className={s.container}>
        <img src={Close} className={s.close} alt='close' onClick={() => setClosing(true)} />
        <div className={s.content}>
          <h1>Join Triphelper</h1>
          <p>Please enter your name and email to get exclusive offers from Triphelper.</p>
          {Fields}
          <div className={fields.email.valid ? `${s.warn} ${s.warnHidden}` : s.warn}>Please enter valid email</div>
          <div className={isValid() ? `${s.btn} ${s.valid}` : s.btn} onClick={submit}>
            Next
          </div>
        </div>
        <img alt='barcelona tours' src={Photo} />
      </div>
    </div>
  )
}
