import { useState } from 'react'

import s from './ThankPopUp.module.css'
import Photo from '../../res/img/thankPopUp.png'
import Close from '../../res/img/close.svg'

export const ThankPopUp = (props) => {
  const [closing, setClosing] = useState(false)

  const handleClick = (e) => {
    return e.target.id === 'blur' && setClosing(true)
  }

  return (
    <div
      className={closing ? `${s.blur} ${s.closing}` : s.blur}
      onAnimationEnd={() => {
        closing && props.close()
      }}
      onClick={handleClick}
      id='blur'
    >
      <div className={s.container}>
        <img src={Close} className={s.close} alt='close' onClick={() => setClosing(true)} />
        <div className={s.content}>
          <div>
            <h1>
              We are grateful for your help
              <br />
              in improving our service
            </h1>
            <p>You will be able to complete your registration soon and get your unique benefits</p>
          </div>
          <div
            className={s.btn}
            onClick={() => {
              setClosing(true)
            }}
          >
            Done!
          </div>
        </div>
        <img alt='barcelona tours' src={Photo} />
      </div>
    </div>
  )
}
