import s from './Footer.module.css'
import mainS from '../../App.module.css'
import Inst from '../../res/img/inst.svg'
import Fb from '../../res/img/fb.svg'
import Tw from '../../res/img/tw.svg'
import In from '../../res/img/in.svg'

export const Footer = () => {
  return (
    <div className={mainS.container}>
      <div className={s.footer}>
        <div className={s.linkList}>
          <a href='/'>
            <img src={Inst} alt='instagram' />
          </a>
          <a href='/'>
            <img src={Fb} alt='facebook' />
          </a>
          <a href='/'>
            <img src={Tw} alt='twitter' />
          </a>
          <a href='/'>
            <img src={In} alt='linkedin' />
          </a>
        </div>
        <a className={s.email} href='mailto:info@triphelper.net'>
          info@triphelper.net
        </a>
        <div className={s.details}>
          <div className={s.info}>
            <div className={s.rights}>© 2022 TripHelper ® - All rights reserved</div>
            <div className={s.links}>
              <div className={s.link}>Privacy</div>
              <div className={s.link}>Terms of Use</div>
            </div>
          </div>
          <a className={s.designer} href='https://www.behance.net/daraeverydcafc'>
            Designed by <span>Bohdan Reshetnyk</span>
          </a>
        </div>
      </div>
    </div>
  )
}
