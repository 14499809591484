import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import mainS from '../../App.module.css'
import { Choice } from '../../Components/QuizComponents/Choice/Coice'
import { Money } from '../../Components/QuizComponents/Money/Money'
import { Range } from '../../Components/QuizComponents/Range/Range'
import { isQuestionValid } from '../../hooks/useQuestionValid'
import s from './Quiz.module.css'
import { close, setSent } from '../../store/quizPopUp/quizPopUp'
import axios from 'axios'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  })
}

export const Quiz = (props) => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
  const contentRef = useRef()
  const dispatch = useDispatch()
  const [question1, setQuestion1] = useState({
    type: 'single',
    title: '1. Do you usually buy tours and/or excursions?',
    valid: true,
    fields: [
      {
        text: 'I always buy guided tours and/or excursions',
        selected: false,
      },
      {
        text: 'Sometimes I buy tours, sometimes I travel on my own',
        selected: false,
      },
      {
        text: 'I always travel on my own',
        selected: false,
      },
      {
        type: 'other',
        value: '',
        selected: false,
        valid: true,
      },
    ],
  })

  const [question2, setQuestion2] = useState({
    type: 'single',
    title: '2. How many tours do you usually buy per trip?',
    valid: true,
    fields: [
      {
        text: '1',
        selected: false,
      },
      {
        text: '2',
        selected: false,
      },
      {
        text: '3',
        selected: false,
      },
      {
        type: 'other',
        value: '',
        selected: false,
        valid: true,
      },
    ],
  })

  const [question3, setQuestion3] = useState({
    type: 'multi',
    title: '3. When planning your itinerary, what tools do you use?',
    valid: true,
    fields: [
      {
        text: 'Google maps',
        selected: false,
      },
      {
        text: 'Tripadvisor',
        selected: false,
      },
      {
        text: 'Google search',
        selected: false,
      },
      {
        type: 'other',
        value: '',
        selected: false,
        valid: true,
      },
    ],
  })

  const [question4, setQuestion4] = useState({
    type: 'range',
    title: '4. Meassure how difficult for you the following things when planning a trip',
    valid: true,
    fields: [
      {
        text: '1. Finding what places to visit',
        min: 1,
        max: 5,
        step: 1, // must be: (max - min)%step === 0
        value: 1,
      },
      {
        text: '2. Planning your itinerary',
        min: 1,
        max: 5,
        step: 1, // must be: (max - min)%step === 0
        value: 1,
      },
      {
        text: '3. Orienteering in the new city',
        min: 1,
        max: 5,
        step: 1, // must be: (max - min)%step === 0
        value: 1,
      },
      {
        text: '4. Budget planning',
        min: 1,
        max: 5,
        step: 1, // must be: (max - min)%step === 0
        value: 1,
      },
    ],
  })

  const [question5, setQuestion5] = useState({
    type: 'money',
    title: '5. How much would you pay for online walking tour (in Euro)',
    value: '',
    placeholder: '1 €',
    valid: true,
  })

  const isFormValid = () => {
    let valid = true
    valid = isQuestionValid(question1) ? valid : false
    valid = isQuestionValid(question2) ? valid : false
    valid = isQuestionValid(question3) ? valid : false
    valid = isQuestionValid(question4) ? valid : false
    valid = isQuestionValid(question5) ? valid : false
    return valid
  }

  const postData = async (data) => {
    try {
      await axios.post('https://proxy-be-v1-rmot67v4eq-lm.a.run.app/api/v1/forms/quiz', data)
    } catch (err) {
      console.log(err)
    }
  }

  const isSendForm = () => {
    if (isFormValid()) {
      eventTrack('Quiz', 'Send Form', 'Successful')
      let toursString
      question1.fields.forEach((el) => {
        if (el.selected) {
          if (el.type === 'other') {
            toursString = el.value
          } else {
            toursString = el.text
          }
        }
      })

      let tourNumString
      question2.fields.forEach((el) => {
        if (el.selected) {
          if (el.type === 'other') {
            tourNumString = el.value
          } else {
            tourNumString = el.text
          }
        }
      })

      let toolsString = ''
      question3.fields.forEach((el) => {
        if (el.type === 'other' && el.selected) {
          toolsString += `${el.value}; `
        } else {
          if (el.selected) {
            toolsString += `${el.text}; `
          }
        }
      })

      let data = {
        name: props.userData.name,
        email: props.userData.email,
        buyToursOrExcursions: toursString,
        toursPerTrip: tourNumString,
        whatTools: toolsString,
        howDifficultFindingWhatPlaces: question4.fields[0].value.toString(),
        howDifficultPlanningItinerary: question4.fields[1].value.toString(),
        howDifficultOrienteeringInTheNewCity: question4.fields[2].value.toString(),
        howDifficultBudgetPlanning: question4.fields[3].value.toString(),
        howMuchToPayForWalkingTour: question5.value.toString(),
      }
      postData(data)
      localStorage.setItem('quiz', 'true')
      dispatch(close())
      dispatch(setSent())
      props.open()
    } else {
      eventTrack('Quiz', 'Send Form', 'Unsuccessful')
      let arr = [{ ...question1 }, { ...question2 }, { ...question3 }, { ...question4 }, { ...question5 }]
      if (!isQuestionValid(question1)) {
        const copy = { ...question1 }
        copy.valid = false
        arr[0].valid = false
        setQuestion1(copy)
      }
      if (!isQuestionValid(question2)) {
        const copy = { ...question2 }
        copy.valid = false
        arr[1].valid = false
        setQuestion2(copy)
      }
      if (!isQuestionValid(question3)) {
        const copy = { ...question3 }
        copy.valid = false
        arr[2].valid = false
        setQuestion3(copy)
      }
      if (!isQuestionValid(question4)) {
        const copy = { ...question4 }
        copy.valid = false
        arr[3].valid = false
        setQuestion4(copy)
      }
      if (!isQuestionValid(question5)) {
        const copy = { ...question5 }
        copy.valid = false
        arr[4].valid = false
        setQuestion5(copy)
      }
      const firstInvalid = arr.findIndex((el) => el.valid === false)
      if (contentRef.current) {
        console.log(contentRef.current.children.item(firstInvalid + 1))
        contentRef.current.children.item(firstInvalid).scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Triphelper: Quiz</title>
      </Helmet>
      <div className={mainS.container}>
        <div className={s.container}>
          <div className={s.content} ref={contentRef}>
            <Choice question={question1} edit={setQuestion1} />
            <Choice question={question2} edit={setQuestion2} />
            <Choice question={question3} edit={setQuestion3} />
            <Range question={question4} edit={setQuestion4} />
            <Money question={question5} edit={setQuestion5} />
            <div className={isFormValid() ? s.submit : `${s.submit} ${s.disabled}`} onClick={isSendForm}>
              Submit
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
