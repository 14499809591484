import s from './Header.module.css'
import mainS from '../../App.module.css'
import Logo from '../../res/img/logo.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { open } from '../../store/authPopUp/authPopUp'

export const Header = () => {
  const dispatch = useDispatch()

  return (
    <div className={s.headerContainer}>
      <div className={mainS.container}>
        <div className={s.header}>
          <Link to='/' className={s.logoLink}>
            <img src={Logo} className={s.logo} alt='triphelper' />
          </Link>
          <div className={s.linkList}>
            <div
              className={s.link}
              onClick={() => {
                dispatch(open())
              }}
            >
              My trips
            </div>
            <div
              className={s.link}
              onClick={() => {
                dispatch(open())
              }}
            >
              Blog
            </div>
            <div
              className={s.link}
              onClick={() => {
                dispatch(open())
              }}
            >
              FAQ
            </div>
          </div>
          <div
            className={s.login}
            onClick={() => {
              dispatch(open())
            }}
          >
            Log in
          </div>
        </div>
      </div>
    </div>
  )
}
