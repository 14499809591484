import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import mainS from '../../App.module.css'
import s from './MobileMenu.module.css'
import Logo from '../../res/img/logo.svg'
import { open as openPopUp } from '../../store/authPopUp/authPopUp'

export const MobileMenu = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [Y, setY] = useState(0)
  const [direction, setDirection] = useState(true)

  const showHeader = useCallback(() => {
    if (window.scrollY > 0 && window.scrollY + window.innerHeight < document.documentElement.scrollHeight) {
      if (Y > window.scrollY) {
        setDirection(true)
      } else {
        setDirection(false)
      }
      setY(window.scrollY)
    } else if (window.scrollY <= 0) {
      setDirection(true)
      setY(0)
    } else {
      setDirection(false)
      setY(document.documentElement.scrollHeight - window.innerHeight)
    }
  }, [Y])

  useEffect(() => {
    window.addEventListener('scroll', showHeader)

    return () => window.removeEventListener('scroll', showHeader)
  }, [showHeader])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  return (
    <div className={open ? `${s.mainDiv} ${s.opened}` : direction ? s.mainDiv : `${s.mainDiv} ${s.hide}`}>
      <div className={s.menuContainer}>
        <div className={mainS.container}>
          <div className={s.menu}>
            <div className={s.header}>
              <img src={Logo} alt='triphelper' />
              <div
                className={s.btnContainer}
                onClick={() => {
                  if (open) {
                    setOpen(2)
                  } else {
                    setOpen(true)
                  }
                }}
              >
                <div className={`${s.line} ${open === true ? s.line1 : null}`}></div>
                <div className={`${s.line} ${open === true ? s.line2 : null}`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open ? (
        <div
          className={open === 2 ? s.menuContentClosing : s.menuContent}
          onAnimationEnd={() => {
            open === 2 && setOpen(false)
          }}
        >
          <div className={`${mainS.container} ${s.content}`}>
            <div className={s.linkList}>
              <div
                className={s.link}
                onClick={() => {
                  setOpen(2)
                  dispatch(openPopUp())
                }}
              >
                My trips
              </div>
              <div
                className={s.link}
                onClick={() => {
                  setOpen(2)
                  dispatch(openPopUp())
                }}
              >
                Blog
              </div>
              <div
                className={s.link}
                onClick={() => {
                  setOpen(2)
                  dispatch(openPopUp())
                }}
              >
                FAQ
              </div>
              <div
                className={`${s.link} ${s.login}`}
                onClick={() => {
                  setOpen(2)
                  dispatch(openPopUp())
                }}
              >
                Log in
              </div>
            </div>
            <a className={s.designer} href='https://www.behance.net/daraeverydcafc'>
              Designed by
              <br />
              <span>Bohdan Reshetnyk</span>
            </a>
          </div>
        </div>
      ) : null}
    </div>
  )
}
