import { useRef } from 'react'
import s from './Choice.module.css'

export const Choice = ({ question, edit }) => {
  const questionRef = useRef()

  const clickHandler = (index) => {
    const copy = { ...question }
    if (question.type === 'single') {
      copy.fields.forEach((field, ind) => {
        if (ind !== index) {
          field.selected = false
        }
      })
    }
    copy.fields[index].selected = !copy.fields[index].selected
    copy.valid = true
    edit(copy)
  }

  const otherClickHandler = (index) => {
    const copy = { ...question }
    if (question.type === 'single') {
      copy.fields.forEach((field, ind) => {
        if (ind !== index) {
          field.selected = false
        }
      })
    }
    copy.fields[index].selected = !copy.fields[index].selected
    copy.valid = true
    edit(copy)

    if (questionRef.current && copy.fields[index].selected) {
      const el = questionRef.current.children.item(index + 1).children.item(1)
      el.focus()
    }
  }

  const changeHandler = (e, index) => {
    const copy = { ...question }
    copy.fields[index].value = e.target.value
    copy.fields[index].valid = true
    copy.valid = true
    edit(copy)
  }

  //   useEffect(() => {})

  const focusHandler = (index) => {
    const copy = { ...question }
    if (question.type === 'single') {
      copy.fields.forEach((field) => (field.selected = false))
    }
    copy.fields[index].selected = true
    if (copy.fields[index].value) {
      copy.valid = true
    }

    edit(copy)
  }

  const blurHandler = (index) => {
    const copy = { ...question }
    if (!copy.fields[index].value) {
      copy.fields[index].valid = false
    }
    edit(copy)
  }

  const Selectors = question.fields.map((field, index) => {
    if (field.type === 'other') {
      return (
        <div className={s.otherBlock} key={`field-${index}`}>
          <div
            className={s.selector}
            key={`field-${index}`}
            onClick={() => {
              otherClickHandler(index)
            }}
          >
            <div className={field.selected ? `${s.point} ${s.selected}` : s.point}></div>
            <p>Other</p>
          </div>
          <input
            className={field.selected ? (field.valid ? s.active : s.invalid) : null}
            value={field.value}
            onChange={(e) => {
              changeHandler(e, index)
            }}
            onFocus={() => {
              focusHandler(index)
            }}
            onBlur={() => {
              blurHandler(index)
            }}
            spellCheck='false'
            autoComplete='off'
          />
          <div className={field.selected && !field.valid ? `${s.errorText} ${s.hidden}` : s.errorText}>
            Fulfill this input field{' '}
          </div>
        </div>
      )
    }

    return (
      <div
        className={s.selector}
        key={`field-${index}`}
        onClick={() => {
          clickHandler(index)
        }}
      >
        <div className={field.selected ? `${s.point} ${s.selected}` : s.point}></div>
        <p>{field.text}</p>
      </div>
    )
  })

  return (
    <div className={s.question} ref={questionRef}>
      <h1 className={!question.valid && s.invalidName} dangerouslySetInnerHTML={{ __html: question.title }} />
      {Selectors}
    </div>
  )
}
