import { configureStore } from '@reduxjs/toolkit'
import quizPopUpReducer from '../store/quizPopUp/quizPopUp'
import authPopUpReducer from '../store/authPopUp/authPopUp'

export const store = configureStore({
  reducer: {
    authPopUp: authPopUpReducer,
    quizPopUp: quizPopUpReducer,
  },
})
