export const isQuestionValid = (question) => {
  if (question.type === 'single') {
    const selected = question.fields.findIndex((el) => el.selected === true)

    if (selected === -1) {
      return false
    }

    if (question.fields[selected].type === 'other' && !question.fields[selected].value) {
      return false
    }

    // console.log('here')

    return true
  }

  if (question.type === 'multi') {
    let valid = false
    for (const el of question.fields) {
      if (el.selected) {
        if (el.type === 'other') {
          valid = !!el.value
          break
        } else {
          valid = true
        }
      }
    }
    return valid
  }

  if (question.type === 'range') {
    return true
  }

  if (question.type === 'money') {
    return !!question.value
  }
}
