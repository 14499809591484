import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import s from './App.module.css'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Landing } from './Sreens/Landing/Landing'
import { Footer } from './Components/Footer/Footer'
import { Header } from './Components/Header/Header'
import { useWindowDimensions } from './hooks/useWindowDimensions'
import { MobileMenu } from './Components/MobileMenu/MobileMenu'
import { AuthPopUp } from './Components/AuthPopUp/AuthPopUp'
import { QuizPopUp } from './Components/QuizPopUp/QuizPopUp'
import { Quiz } from './Sreens/Quiz/Quiz'
import { setSent as setSentAuth, close as closeAuth } from './store/authPopUp/authPopUp'
import { setSent as setSentQuiz, open as openQuiz, close as closeQuiz } from './store/quizPopUp/quizPopUp'
import { ThankPopUp } from './Components/ThankPopUp/ThankPopUp'
import ReactGA from 'react-ga'

function App() {
  const location = useLocation()
  ReactGA.initialize(window._env_.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
  const history = useNavigate()
  const { width } = useWindowDimensions()
  const authPopup = useSelector((state) => state.authPopUp)
  const quizPopup = useSelector((state) => state.quizPopUp)
  const [thank, setThank] = useState(false)
  const dispatch = useDispatch()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (width > 1190) {
      document.body.style.overflow = 'auto'
    }
  }, [width])

  useEffect(() => {
    const isAuthPassed = localStorage.getItem('auth')
    const isQuizPassed = localStorage.getItem('quiz')
    if (isAuthPassed) {
      dispatch(setSentAuth())
    }
    if (isQuizPassed) {
      dispatch(setSentQuiz())
    }
  }, [dispatch])

  useEffect(() => {
    if (authPopup.open) {
      if (authPopup.sent) {
        dispatch(closeAuth())
        dispatch(openQuiz())
      }
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [authPopup, dispatch])

  useEffect(() => {
    if (quizPopup.open) {
      if (quizPopup.sent) {
        dispatch(closeQuiz())
        setThank(true)
      }
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [quizPopup, dispatch])

  useEffect(() => {
    if (thank) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [thank])

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [location])

  return (
    <div className={s.App}>
      {authPopup.open ? <AuthPopUp setData={setUserData} /> : null}
      {quizPopup.open ? <QuizPopUp /> : null}
      {thank ? (
        <ThankPopUp
          close={() => {
            history('/')
            setThank(false)
          }}
        />
      ) : null}
      {width > 1190 ? <Header /> : <MobileMenu />}
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/quiz' element={<Quiz open={() => setThank(true)} userData={userData} />} />
        <Route path='/*' element={<Landing />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
