import { useDispatch } from 'react-redux'

import s from './Landing.module.css'
import mainS from '../../App.module.css'
import HeaderImage from '../../res/img/headerImage.png'
import HeaderImageSmall from '../../res/img/headerImageSmall.jpg'
import Phone1 from '../../res/img/phone1.png'
import Phone2 from '../../res/img/phone2.png'
import Phone3 from '../../res/img/phone3.png'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { open } from '../../store/authPopUp/authPopUp'
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const eventTrack = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const Landing = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const isPc = () => {
    return width > 1190
  }
  return (
    <>
      <Helmet>
        <title>Triphelper: Walking tours on your phone</title>
      </Helmet>
      <div className={mainS.container}>
        <div className={s.headSection}>
          <div className={s.content}>
            <h1>Walking tours on your phone</h1>
            <p>
              Explore Barcelona at your own pace with best self-guided tours created
              {isPc() ? <br /> : ' '}
              and rated by locals and other travelers
            </p>
            <div
              className={s.signup}
              onClick={() => {
                eventTrack("Landing", "Sign Up", "Top");
                dispatch(open())
              }}
            >
              Sign up
            </div>
          </div>
          <img src={isPc() ? HeaderImage : HeaderImageSmall} alt='Walking tours' />
        </div>
        <div className={s.stats}>
          <div>
            <h3>100+</h3>
            <p>
              Tours
              {isPc() ? <br /> : ' '}
              in Barcelona
            </p>
          </div>
          <div>
            <h3>2,000+</h3>
            <p>Tours reviews</p>
          </div>
          <div>
            <h3>10,000+</h3>
            <p>Users</p>
          </div>
        </div>
      </div>
      <div className={s.featureBlock}>
        <img src={Phone1} alt='self-guided tours' />
        <div className={s.content}>
          <h2>Be your own guide</h2>
          <p>
            Forget about big groups and schedules.
            {isPc() ? <br /> : ' '} You can travel to Barcelona independently
            {isPc() ? <br /> : ' '} with our interactive map.
          </p>
        </div>
      </div>
      <div className={s.featureBlockEven}>
        <img src={Phone2} alt='trip planning' />
        <div className={s.content}>
          <h2>Choose and go</h2>
          <p>
            Save time on planning your day.{isPc() ? <br /> : ' '}Triphelper has a large number of tours
            {isPc() ? <br /> : ' '}for any type of traveler created{isPc() ? <br /> : ' '}by locals and other travelers.
          </p>
        </div>
      </div>
      <div className={s.featureBlock}>
        <img src={Phone3} alt='travel benefits' />
        <div className={s.content}>
          <h2>Get benefits{!isPc() ? <br /> : ' '}from traveling</h2>
          <p>
            Earn Travelcoins for giving reviews
            {isPc() ? <br /> : ' '} and creating your trips in Triphelper.
            {isPc() ? <br /> : ' '} The more people choose your trip,
            {isPc() ? <br /> : ' '} the more Travelcoins you get.
          </p>
        </div>
      </div>
      <div className={mainS.container}>
        <div className={s.startBlock}>
          <div
            className={s.signup}
            onClick={() => {
              eventTrack("Landing", "Sign Up", "Bottom");
              dispatch(open())
            }}
          >
            Get started
          </div>
          <h1>
            Let’s start your first
            <br />
            travel with Triphelper!
          </h1>
        </div>
      </div>
    </>
  )
}
