import s from './Range.module.css'
import { useWindowDimensions } from '../../../hooks/useWindowDimensions'

export const Range = ({ question, edit }) => {
  const { width } = useWindowDimensions()
  const changeHandler = (e, index) => {
    const copy = { ...question }
    copy.fields[index].value = e.target.value
    edit(copy)
  }

  const mouseUpHandler = () => {
    console.log('here')
  }

  const getLabels = (index) => {
    const field = question.fields[index]
    const labels = []
    let val = field.min
    while (val <= field.max) {
      labels.push(val)
      val += field.step
    }

    return (
      <div className={s.labelContainer}>
        {labels.map((label) => {
          return (
            <div
              key={`${index}-label-${label}`}
              className={label === +field.value ? `${s.active} ${s.label}` : s.label}
            >
              {label}
            </div>
          )
        })}
      </div>
    )
  }

  const getBackgroundGradient = (index) => {
    const field = question.fields[index]
    const percentStep = 100 / (field.max / field.step - 1)
    let min = field.min
    let percent = 0
    while (min < field.value) {
      percent += percentStep
      min += field.step
    }
    return `linear-gradient(to right, #8AAF45 0%, #8AAF45 ${percent}%, #EDE9DE ${percent}%, #EDE9DE 100%)`
  }

  const Fields = question.fields.map((field, index) => {
    return (
      <div className={s.rangeContainer} key={`range-${index}`}>
        <h2>{field.text}</h2>
        {width <= 1190 ? getLabels(index) : null}
        <input
          type='range'
          style={{ background: getBackgroundGradient(index) }}
          value={field.value}
          min={field.min}
          max={field.max}
          step={field.step}
          onChange={(e) => {
            changeHandler(e, index)
          }}
          onMouseUp={mouseUpHandler}
        />
        {width > 1190 ? getLabels(index) : null}
      </div>
    )
  })

  return (
    <div className={s.question}>
      <h1 className={!question.valid && s.invalidName} dangerouslySetInnerHTML={{ __html: question.title }} />
      {Fields}
    </div>
  )
}
