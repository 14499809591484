import { useRef } from 'react'
import s from './Money.module.css'

export const Money = ({ question, edit }) => {
  const inputRef = useRef()

  const changeHandler = (e) => {
    const copy = { ...question }
    // const val = e.target.value

    // if (val.length === 1 && !isNaN(val)) {
    //   copy.value = val + ' €'
    //   return edit(copy)
    // } else {
    //   if (val === ' €') {
    //     copy.value = ''
    //     return edit(copy)
    //   } else {
    //     const num = val.replace(' €', '')
    //     if (!isNaN(num)) {
    //       copy.value = val
    //       return edit(copy)
    //     }
    //   }
    // }

    if (!isNaN(e.target.value)) {
      copy.value = e.target.value.trim()
      copy.valid = true
    }

    edit(copy)
  }

  // const cursorPosition = () => {
  //   if (inputRef.current) {
  //     const input = inputRef.current
  //     if (input.value.includes(' €')) {
  //       input.setSelectionRange(input.value.length - 2, input.value.length - 2)
  //     }
  //   }
  // }

  return (
    <div className={s.question}>
      <h1 className={!question.valid && s.invalidName} dangerouslySetInnerHTML={{ __html: question.title }} />
      <input
        type='text'
        placeholder={question.placeholder}
        value={question.value}
        onChange={changeHandler}
        ref={inputRef}
        // onKeyDown={cursorPosition}
        // onMouseDown={cursorPosition}
        // onFocus={cursorPosition}
      />
    </div>
  )
}
