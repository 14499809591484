import { Link } from 'react-router-dom'
import { useState } from 'react'

import { useDispatch } from 'react-redux'

import s from './QuizPopUp.module.css'
import Photo from '../../res/img/quizPopUp.png'
import Close from '../../res/img/close.svg'
import { close } from '../../store/quizPopUp/quizPopUp'

export const QuizPopUp = () => {
  const dispatch = useDispatch()
  const [closing, setClosing] = useState(false)

  const handleClick = (e) => {
    return e.target.id === 'blur' && setClosing(true)
  }

  return (
    <div
      className={closing ? `${s.blur} ${s.closing}` : s.blur}
      onAnimationEnd={() => {
        closing && dispatch(close())
      }}
      onClick={handleClick}
      id='blur'
    >
      <div className={s.container}>
        <img src={Close} className={s.close} alt='close' onClick={() => setClosing(true)} />
        <div className={s.content}>
          <div>
            <h1>
              Thank you! We are imroving
              <br />
              our product now.
            </h1>
            <p>
              Once our service is ready you will get exclusive offers. You could get <span>free lifetime access</span> to unique
              features by answering a few questions.
            </p>
          </div>
          <Link
            to='/quiz'
            className={s.btn}
            onClick={() => {
              setClosing(true)
            }}
          >
            Answer questions
          </Link>
        </div>
        <img alt='barcelona tours' src={Photo} />
      </div>
    </div>
  )
}
